.device-detail-page {
  .banner {
    // display: flex;
    // justify-content: space-between;
    background-color: white;
    border-radius: 0 0 8px 8px;
    padding: 24px 20px;

    .filters {
      margin-top: 20px;
    }
  }

  .content {
    margin-top: 16px;
    background-color: #fff;
    border-radius: 8px;
    padding: 24px 20px;
    height: calc(100vh - 310px);
  }
}
