.page-header {
  display: flex;
  justify-content: space-between;

  h1 {
    font-size: 18px;
    font-weight: 700;
    color: white;
    cursor: pointer;
  }

  .right {
    display: flex;
    flex-direction: row;

    .menu {
      display: block;

      .user {
        font-size: 14px;
        color: white;
      }
    }
  }
}
