.network-config-page {
  display: inline-block;
  padding: 20px;

  .ant-form-item-control-input {
    width: 250px;
  }

  .footer {
    display: flex;

    button {
      margin-left: auto;
    }
  }
}
