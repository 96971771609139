.modification-config {
  .editable-row {
    div {
      margin: 0;
    }
  }

  .top {
    display: flex;

    .add-btn {
      width: 100px;
      margin-bottom: 20px;
    }
  }

  .bottom {
    display: flex;
    margin-top: 20px;

    button {
      margin-left: 20px;
    }
  }

  .right {
    margin-left: auto;

    button {
      margin-left: 20px;
    }
  }

  .ant-table-wrapper {
    max-height: calc(100vh - 210px - 64px - 260px);
    overflow: auto;
  }

  .actions-item {
    button {
      &:first-child {
        padding-left: 0;
      }
    }
  }
}
