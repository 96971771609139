.response-config {
  .actions {
    display: flex;

    .add-btn {
      margin-left: auto;
      width: 100px;
      margin-bottom: 20px;
    }
  }

  td {
    display: table-cell;
    max-width: 700px;
    overflow: auto;
  }

  .response-content {
    max-height: 300px;
  }

  .res .actions-item {
    button {
      &:first-child {
        padding-left: 0;
      }
    }
  }
}

.api-edit {
  .response-editor {
    height: 300px;
  }
}
