@import url('@/assets/styles/mixin.less');

.login-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  .backgroundImage('~@/assets/imgs/background.png');

  .login-wrapper {
    border-radius: 30px;
    padding: 70px 30px 30px;
    backdrop-filter: blur(5px);
    background: hsla(0, 0%, 100%, 0.75);
    border-radius: 16px;

    .banner {
      display: flex;
      align-items: center;
      margin-bottom: 50px;

      svg {
        width: 52px;
        height: 46px;
      }

      p {
        margin-left: 17px;
        margin-bottom: 0;
        font-size: 33px;
        font-weight: 900;
        line-height: 38px;
        letter-spacing: 0.005em;
        color: rgba(0, 0, 0, 0.85);
      }
    }

    .google-login {
      display: flex;
      justify-content: center;
    }
  }
}
