.device-list-page {
  .banner {
    // display: flex;
    // justify-content: space-between;
    background-color: white;
    border-radius: 0 0 8px 8px;
    padding: 24px 20px;

    h2 {
      font-weight: 500;
      font-size: 20px;
      color: rgba(0, 0, 0, 0.85);
      margin: 0;
    }

    .filters {
      margin-top: 20px;
    }
  }

  .content {
    margin-top: 16px;
    background-color: #fff;
    border-radius: 8px 8px 0 0;
    // height: calc(100vh - 204px);
  }

  tbody {
    tr {
      cursor: pointer;
    }
  }
}
