.mainPage {
  margin: 1px 16px 0 16px;
  color: rgba(0, 0, 0, 0.85);
}

.project-selector {
  padding: 0 10px;
  margin-top: 20px;
  width: 100%;

  .selector {
    width: 100%;
  }

  .label {
    font-weight: bold;
  }
}
